
.boxCalendarAgend{
    margin: auto;
    align-items: center;
    height: 6rem;
    width: 12rem;
    background: white;
    border-left: #0C8CE9 3.2px solid;
    border-bottom: #DFDFDF 1px solid;
    border-top: #DFDFDF 1px solid;
    border-right: #DFDFDF 1px solid;
    border-radius: 5px 10px 10px 5px;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
}

.boxCalendarAgend p{
    margin-top: 0.2rem;
    font-weight: 500;
    font-size: 10pt;
    color: #0C8CE9;
}


.boxCalendarAgend label{
color: rgb(180, 180, 180);
font-weight: 400;
}
.gridCalendars {
    margin-top: 4rem;
    margin-left: 5rem;
    display: grid;
    grid-template-columns: repeat(5, 0.1fr);
    gap: 10px; /* Ajuste o espaçamento entre as colunas conforme necessário */
}
.infosAgend{
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: auto;
    padding-left: 1rem;
    margin-top: 1rem;
}

.infosAgend h2{
    font-size: 16pt;
}

.pddLb{
    padding: 0px;
}

.cancelAgend{
    height: 1.5rem;
    width: 1.5rem; 
    background: #80808027;
    position: absolute;
    margin-top: -1rem;
    margin-left: 9.4rem;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 0.5s;
    cursor: pointer;
}

.cancelAgend svg{
    padding-left: 1px;
    padding-top: 0.5px;
}

.cancelAgend:hover {
    background-color: #FDDEDB;
}

.cancelAgend:hover .svgIcon path {
    fill: #F4614D;
}
