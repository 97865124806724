.lineIngress {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    width: 22rem;
}

.lineIngress h3 {
    font-size: 20pt;
    font-weight: 400;
}

.inptKey {
    margin-top: 15px;
    width: 22rem;
    display: flex;
    flex-direction: column;
}

.inptkeyW {
    width: 20rem;
}

.ctaKey {
    width: 22rem;
}

.closeBtt {
    height: 1.5rem;
    width: 90%;
    align-items: end;
    justify-content: right;
    text-align: right;
    position: fixed;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.closeBtt svg{
    cursor: pointer;
}