.newProductToForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 335px;
    background: #EFEFEF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;

    text-align: center;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.newProductToForm.drag-active {
    background-color: #f0f0f0;
}

.upload-label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14pt;
    color: #BFBFBF;
}

.upload-icon {
    color: #BFBFBF;
    margin-bottom: 10px;
}

.upload-label:hover .upload-icon,
.upload-label:hover p {
    color: #BFBFBF;
}

.upload-label:focus .upload-icon,
.upload-label:focus p {
    outline: none;
    color: #555;
}

.upload-label p {
    font-size: 1em;
    color: #BFBFBF;
    margin-top: 10px;
}

.newProductToForm p {
    font-size: 0.9em;
    color: #BFBFBF;
    margin-top: 10px;
}

.sendFile {
    display: none;
}


.boxProduct {
    display: flex;
    align-items: start;
    flex-direction: row;
    margin-top: 1rem;
    margin-left: 5rem;
    height: 26rem;
}

@media (max-width: 768px) {
    .newProductToForm {
        width: 90%;
        height: 150px;
    }

    .upload-icon {
        font-size: 1.5em;
    }

    .upload-label p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .newProductToForm {
        height: 120px;
    }

    .upload-icon {
        font-size: 1.2em;
    }

    .upload-label p {
        font-size: 0.8em;
    }
}

.titleNewProduct {
    margin-left: 5rem;
    margin-top: 3rem;

}

.titleNewProduct h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 17pt;
}

.formProduct {

    width: 30rem;
    height: 100%;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
}

.desc {
    min-height: 3.5rem;
    max-height: 3.5rem;
    min-width: 18rem;
    max-width: 18rem;


}

.desc:focus{
    border: #0C8CE9 1px solid;
}

.lineProduct{
    width: 20rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
}

.lineProduct div{
display: flex;
flex-direction: column;
margin: auto;
}

.lineProduct div:first-child{
    margin-left: 0px;
}
.lineProduct div:last-child{
    margin-right: 0px;
}

.lineLink{
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
}

.lineLink div{
display: flex;
flex-direction: column;
margin: auto;
}

.lineLink div:first-child{
    margin-left: 0px;
}
.lineLink div:last-child{
    margin-right: 0px;
}

.minPrice{
    width: 5rem;
}

.selectTypePrice{
height: 2.5rem;
width: 5.5rem;

padding: 0 0px 0 10px;
}

.medPrice{
    width: 11rem;
}
.medLink{
    width: 9rem;
}
textarea:focus{
    border: #0C8CE9 1px solid;
}

.buttonTop{
    margin-top: 0.5rem;
}

.linkTop{
    margin-top: 1rem;
}

.fileLink{
    height: 100%;
}