.boxProductItem {
    border: 1px rgb(225, 225, 225) solid;
    background: white;
    height: 30rem;
    width: 80%;
    margin-left: 5rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #a8a8a816 0px 0px 15px 5px;
}

.productPhotos {

    height: 30rem;
    width: 30rem;
    border-radius: 10px;
}

.productInfosItem {
    height: 30rem;
    width: 40rem;
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
}

.productInfosItem p {
    color: #A8A8A8;
    margin-top: -2px;
    padding: 0px;
}

.productInfosItem h1 {
    font-size: 20pt;
}

.productInfosItem h2 {
    font-size: 20pt;
    font-weight: 500;
    margin-top: -5px;
}


.marginProduct {
    min-height: 2.5rem;
    margin-top: 2rem;
}

.marginProduct1 {
    margin-top: 1rem;
}

#noneNorm {
    color: white;
    align-items: center;
    margin: auto;
}


.boxProductItem {
    border: 1px rgb(225, 225, 225) solid;
    background: white;
    height: 30rem;
    /* Altura padrão */
    width: 80%;
    margin-left: 5rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    box-shadow: #a8a8a816 0px 0px 15px 5px;
    transition: height 0.3s ease;
    /* Adiciona uma transição suave para a mudança de altura */
}

/* Quando o conteúdo estiver expandido, a altura aumenta */
.boxProductItem.expanded {
    height: 40rem;
    /* Altura expandida */
}

.productPhotos {
    height: 30rem;
    width: 30rem;
    border-radius: 10px;
}

.productInfosItem {
    height: 30rem;
    width: 40rem;
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
}

.productInfosItem p {
    color: #A8A8A8;
    margin-top: -2px;
    padding: 0px;
}

.productInfosItem h1 {
    font-size: 20pt;
}

.productInfosItem h2 {
    font-size: 20pt;
    font-weight: 500;
    margin-top: -5px;
}

.marginProduct {
    margin-top: 2rem;
}

.marginProduct1 {
    margin-top: 1rem;
}

#noneNorm {
    color: white;
    align-items: center;
    margin: auto;
}

/* Estilo do link "Ler mais" e "Ler menos" */
.toggleDescription {
    color: #007BFF;
    cursor: pointer;
    font-weight: 400;
}