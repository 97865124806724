#footerBar{
min-width: 100%;
background: rgb(255, 255, 255);
border-top:  1px solid rgb(234, 234, 234);
height: 74px;
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
}


#TextFooter{
    padding: 0px;
    margin: 0;
    font-size: 9pt;
}

#TextFooter h6{
    font-size: 11pt;
}


#TextFooter p{
    font-size: 9pt;
    color: rgb(157, 157, 157);
}
