
@tailwind components;
@tailwind utilities;


/* Configurações personalizadas */
@layer components {
  .bg-bgInpt {
    background-color: #F3F3F3;
  }
  .border-bdInpt {
    border-color: #E1E1E1;
  }
  .bg-bgInptSelc:hover, .bg-bgInptSelc:focus {
    background-color: #F1F9FF;
  }
  .border-bdInptSelc:hover, .border-bdInptSelc:focus {
    border-color: #0C8CE9;
  }
  .text-input-text {
    color: #000000;
  }
  .font-inter {
    font-family: 'Inter', sans-serif;
  }
  .text-base {
    font-size: 11pt;
  }
}
