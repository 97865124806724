.cardRounds {
    height: 22rem;
    width: 15rem;
    font-family: 'Inter';
    letter-spacing: 0px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    color: #D9D9D9;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}



.roundItem h3 {
    font-size: 12pt;
    font-weight: 600;
    color: black;

}

.roundItem strong {
    color: rgb(49, 49, 49);
    letter-spacing: 0px;
}

.roundItem {
    width: 87%;
    height: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0px 15px 15px 15px;
}

.roundItem p {
    padding-top: 2px;
}

.topMargin {
    margin-top: .5rem;
}

.gridCards {
    width: 68%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    margin-top: 3rem;
    margin-left: 5rem;
    /* Ajuste o valor conforme necessário para espaçamento entre os cards */
}

.labelRound {
    color: #9C9C9C;
    font-family: 'Inter';
    font-size: 11pt;
    font-weight: 400;
    letter-spacing: 0px;
    height: 1rem;
    margin-top: 0.2rem;
    cursor: pointer;

}

.labelRound:first-child {
    margin-top: 1rem;
}

.descRound {
    color: black;
    font-family: 'Inter';
    font-size: 12pt;
    font-weight: 600;
    letter-spacing: 0px;
    height: 1.5rem;
    width: 100%;
    margin-top: -0.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.roundLine {
    display: flex;
    width: 90%;
    margin-top: 1rem;
    height: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-between;

}

.roundLine svg {
    margin-right: 0.2rem;
}



.statusRound {
    width: 5rem;
    height: 1.2rem;
    border-radius: 5px;
    font-size: 11pt;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 500;
    font-family: 'Inter';
}

.statusRound.active {
    background-color: #A3FFA8;
    color: #01ba0b;
}

.statusRound.coming {
    background-color: #FFD9A3;
    color: rgb(224, 123, 0);
    width: 6rem;

}

.statusRound.inactive {
    background-color: #E3E3E3;
    ;
    color: rgb(131, 131, 131);
    width: 6rem;
}



.trashRound {
    background: rgb(238, 238, 238);
    height: 1.75rem;
    width: 1.75rem;
    position: absolute;
    margin-left: 11.7rem;
    margin-top: -0.2rem;
    border-radius: 0.05cm;

    display: none;
}

.trashRound svg {
    margin: auto;
}

.trashRound {
    transition: all 0.5s;
    align-items: center;
    justify-content: center;
}

.trashRound:hover {
    background: rgba(138, 0, 0, 0.342);
}

.trashRound:hover svg path {
    stroke: red;
    /* Muda a cor da linha do ícone SVG para vermelho ao passar o mouse */
}


@media (max-width: 480px) {

    .gridCards {
        height: 100%;
        width:85%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        gap: 50px;
        grid-template-columns: repeat(1, 1fr);
    }

    .cardRounds {
        width: 99%;
    }
}