.transBtt {
    width: 16em;
    height: 2.2rem;
    background: #fd7e0024;
    border: 1.5px solid #fd7e0088;
    border-radius: 10px;
    display: flex;
    align-items: center;

}

.transBtt svg {
    margin: auto;
    margin: auto 7px auto auto;
}

.transBtt p {
    margin: auto auto auto 0px;
    font-size: 11pt;
}

.language-options {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    /* Ajuste conforme necessário */
}

.language-options button {
    width: 8rem;
    height: 2.2rem;
    background: #F3F3F3;
    border: 1.5px solid #E1E1E1;
    border-radius: 10px;
    cursor: pointer;
    padding: 0.5rem;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0.5rem;
    /* Espaçamento entre os botões */
    display: flex;
}

