.box-center{
    display: flex;
    margin: auto;
    max-width: 100%;
    height: 100vh;
    flex-direction: column;
}

.box-qts{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    min-width: 60%;
    max-width: 70%;
    min-height: 25em;
    transition: 0.75s;
margin-top: -2.5rem;
}

.box-content{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    min-height: 92%;
    margin-bottom: 1.75rem;
    margin-top: 0.75rem;
    padding-top: 15px;
    padding-bottom: 15px;
}

.box-title{
    width: 89%;
    min-height: 2.75em;
    align-items: center;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
}

.box-title H1{
    color: white;
    font-weight: normal;
    font-size: 14.5pt;
    
}

.box-alternativas{
    height: 60%;
    width: 90%;
    color: white;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}


.alter{
    display: flex;
    /*border: 1px gold solid;*/
    background-color: #171717;
    justify-content: space-between;
    border-radius: 5px;
    min-height: 1em;
    min-width: 80%;
    border: 1.5px solid #3C3C3C;
    margin-top: 1rem;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    padding: 1rem;
}

.alter.hover{
    background-color: #FEFEFE;
    color: black;
    box-shadow: #fefefe58 3px 3px 50px;
}


.back{
    position: fixed;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-top: 2rem;
    min-width: 25%;
    height: 3em;
    background-color: #1c1c1c51;
    backdrop-filter: blur(20px);
    border: 1.5px solid #3C3C3C;
    transition: 0.75s;
    cursor: pointer;
    color: white;
    align-items: center;
    text-align: center;
    top: 82%;
    left: 37%
}

.back:hover{
    background-color: #FEFEFE;
    color: black;
    box-shadow: #fefefe58 3px 3px 50px;
}

.back h1{
   margin: auto;
   font-size: 12pt;
}


@media screen and (max-width: 575px) {

    .box-alternativas{
        height: 70%;
    }
    .box-center{
width: 90%;
height: 110vh;
    }
.box-qts{
    max-width: 25rem;
    margin-top: -3rem;
}
    .box-title{
        width: 90%;
        font-size: 12pt;
        text-align: left;
        justify-content: center;

    }
    .box-title h1{
        font-size: 13pt;
    }
    .alter{
        width: 92%;
        margin-left: 0;
    }

    .box-content{
        width: 95%;
    }
    .back{
        display: flex;
        position: static;
        top: 72%;
        left: 29%
    }
}
