@media print {
  body {
    color: white;
  }
}

@page {
  size: 16in 45.95in;
  margin: 0;
}

body{

  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  font-family: 'manrope';
  font-weight: normal;
}


.sum-all{
  background-color: #000000;
}


.dirTable {
  display: flex;
  height: 12rem;
  width: 99%;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  display: none;
}

.dirTable h1 {
  color: white;
  font-family: 'sweet';
  font-weight: normal;
  font-size: 11pt;
  height: 3rem;
  width: 10rem;
}

.dirTable p {
  color: white;
  font-family: 'manrope';
  font-weight: 700;
  font-size: 18pt;
  height: 4rem;
  align-items: end;
  text-align: right;
  justify-content: end;
}

.item1,
.item2,
.item3,
.item4 {

  display: flex;
  flex-direction: row;
  height: 2.75rem;
  width: 100%;
  border-bottom: #797979 solid 1px;
  display: none;
  justify-content: space-between;
}

@font-face {
  font-family: 'manrope';
  src: url("../../assets/fonts/Manrope/Manrope-SemiBold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: 'manrope';
  src: url("../../assets/fonts/Manrope/Manrope-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: 'sweet';
  src: url("../../assets/fonts/Sweet/SweetSansPro-Medium.otf");
  font-weight: normal;
}

@font-face {
  font-family: 'manrope';
  src: url("../../assets/fonts/Manrope/Manrope-Regular.ttf");
  font-weight: 500;
}

.box-center-resultado {
  /* height: 240rem; */
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.start {
  cursor: pointer;
  height: 2.2rem;
  background-color: #1c1c1c38;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  border-radius: 10px;
  border: 0.5px solid #797979;
  color: white;
  font-size: 10pt;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  display: none;

}

.start h1 {
  margin: auto;
}

.start:hover {
  background-color: #FEFEFE;
  color: black;
  box-shadow: #fefefe58 3px 3px 50px;
}

.bem-vindo {
  height: 5rem;
  margin: auto;
  font-size: 14pt;

}

.bem-vindo h1 {
  line-height: 32px;
  font-weight: bold;
  font-family: 'manrope';
}

#get {
  font-style: italic;
  font-weight: 500;
}

.title-resultado {
  height: 7rem;
  width: 33rem;
  display: flex;
  padding-bottom: 25px;
  margin: auto;
  color: white;
  text-align: center;
  flex-direction: column;
  font-size: 15pt;
  font-weight: bold;
}

.line-2 {
  display: flex;
  flex-direction: row;
  height: 20rem;
  width: 84%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

}

.line-3,
.line-4,
.line-5,
.line-6,
.line-7,
.line-8 {
  display: flex;
  flex-direction: row;
  height: 32rem;
  width: 84%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

}

.line-text {
  display: flex;
  flex-direction: row;
  height: 12rem;
  width: 84%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

}


.line-1 {
  display: flex;
  flex-direction: row;
  height: 32rem;
  width: 90%;
  margin-top: 0rem;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}


.box-resultado-geral-p1 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 27rem;
  height: 25.5rem;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
  flex-direction: column;
  padding: 1.5rem;

}

.box-resultado-geral-p2 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 25rem;
  height: 25.5rem;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
  flex-direction: column;
  padding: 1.5rem;

}

.box-resultado-geral-p3 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  border-radius: 20px;
  width: 62rem;
  height: 21em;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
}


.box-resultado-geral-p4,
.box-resultado-geral-p5 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 59rem;
  height: 20rem;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
  flex-direction: row;
  padding: 1.5rem;
}

.box-resultado-text {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  width: 59rem;
  height: 6rem;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
  flex-direction: row;
  padding: 1.5rem;
}

.box-resultado-geral-p1 {
  transition: 1s;
}

.box-resultado-geral-p2 {
  transition: 2s;
}


.title-resultado {
  height: 7rem;
  width: 33rem;
  display: flex;
  padding-bottom: 25px;
  margin: auto;
  color: white;
  text-align: center;
  flex-direction: column;
  font-size: 15pt;
  font-weight: bold;
}

.content-resultado {
  width: 86%;
  height: 91%;
  margin: auto;
}

.content-resultado-2 {
  padding-top: 1rem;
  width: 93%;
  height: 91%;
  margin: auto;
}

strong {
  color: white;
  font-weight: bold;
}



.bem-vindo {
  height: 5rem;
  margin: auto;
  font-size: 14pt;


}

.bem-vindo h1 {

  line-height: 32px;
  font-weight: bold;
  font-family: 'manrope';
}

.logo {

  height: 12rem;
  width: 15rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: flex;
}

.logo img {

  height: 5rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}




#get {
  font-style: italic;
  font-weight: 500;
}

.start {
  cursor: pointer;
  height: 2.2rem;
  background-color: #1c1c1c38;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  border-radius: 10px;
  border: 0.5px solid #797979;
  color: white;
  font-size: 10pt;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  display: none;
}

.start h1 {
  margin: auto;
}

.start:hover {
  background-color: #FEFEFE;
  color: black;
  box-shadow: #fefefe58 3px 3px 50px;
}

#item {
  font-family: 'sweet';
  font-weight: normal;
  color: rgb(196, 196, 196);
  font-size: 10pt;
  letter-spacing: 3px;
}



.empresa h1 {
  color: white;
  font-family: 'manrope';
  font-weight: bold;
}


.table-nota {
  margin-top: 1.75rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nota {
  height: 1.5rem;
  width: 15rem;
}

.nota h1 {
  color: white;
  font-family: 'manrope';
  font-weight: bold;
  font-size: 30pt;
}

.level {
  width: 23rem;
  height: 100%;
  text-align: right;
}


.level H1 {
  color: white;
  font-family: 'manrope';
  font-weight: bold;
  font-size: 21pt;
  padding-top: 10px;
  width: 100%;
}

.dptos {
  height: 15rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.adm,
.com,
.dir,
.finan,
.mkt {
  height: 2.1rem;
  font-size: 7pt;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
  align-items: end;
  border-bottom: 0.5px solid #79797913;

}


.title-dptos {
  font-family: 'manrope';
  color: white;
  font-size: 12pt;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid #7979793a;
}

.pts {
  color: white;
  font-size: 11pt;

}

.nota-l2 {
  height: 5rem;
  flex-direction: row;
}

.nota-l2 h1 {
  color: white;
  font-family: 'manrope';
  font-weight: bold;
  font-size: 30pt;
}

.text-resultado {
  height: 12rem;


}

.text-resultado h1 {
  padding-top: 10px;
  color: rgb(211, 211, 211);
  font-family: 'manrope';
  font-weight: 500;
  font-size: 12pt;
}

.chart {
  height: 100%;
  width: 100%;
}





.box-footer {
  position: fixed;
  height: 5rem;
  width: 100%;
  background-image: linear-gradient(to top, #1c1c1c, #1c1c1c17);
  backdrop-filter: blur(0.75px);
  top: 90%;
  left: 0;
  margin: auto;
  align-items: end;
  display: flex;
}

.box-footer:hover {
  top: 90%;
  left: 0;

}



.box-down {

  margin: auto;
  height: 2.5rem;
  width: 12rem;
  align-items: center;
  display: flex;
}

.line-text {
  padding-top: 10px;
  color: rgb(211, 211, 211);
  font-family: 'manrope';
  font-weight: 500;
  font-size: 12pt;

}


button {
  margin: auto;
  height: 2.5rem;
  width: 12rem;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
  border: 2px white solid;
  color: black;
  display: flex;
  flex-direction: row;

}

button:hover {
  color: black;
  background-color: rgb(208, 208, 208);
  border: 2px rgb(208, 208, 208) solid;
}


button h1 {
  margin: auto;
  font-family: 'manrope';
  font-weight: bold;
  font-size: 10pt;
}


button img {
  fill: white;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 1.5rem;
}

button img:hover {
  fill: black;
}


.content-dpto {
  height: 85%;
  width: 26rem;
  margin-top: 0;
  margin-right: auto;
  margin-left: 0;
  color: white;
  padding-left: 10px;
}

.content-dpto h1 {
  font-size: 24pt;
  font-weight: bold;
}

.content-dpto p {
  padding-top: 10px;
  color: rgb(211, 211, 211);
  font-family: 'manrope';
  font-weight: 500;
  font-size: 12pt;
  width: 23rem;
}

.frase-final {
  height: 7rem;
  width: 60%;
  color: white;
  margin: auto;
  display: flex;
  backdrop-filter: blur(20px);
  border: 0.5px solid #7979792a;
  transition: 0.75s;
  border-radius: 25px;
  padding: 20px;
  text-align: center;
  margin-top: -5rem;
}

.frase-final p {
  color: #797979;
}


.footer {

  width: 100%;
  height: 10rem;
}

canvas {

  margin: auto;
}

.center-box {
  width: 65%;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.chart-js,
.chart-js2,
.chart-js3 {
  width: 400px;
  height: auto;
  margin: auto;

}

.content-chart {
  height: 99%;
  width: 99%;
}

.title-chart h1 {
  color: white;
}

.sub-chart p {
  font-family: 'manrope';
  font-weight: normal;
  color: #535353;
}

.chart-box {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  height: 16rem;
  width: 100%;
  margin-bottom: auto;
  justify-content: space-between;
}

.bar {

  height: 3.5rem;

}

.bar-dir {
  height: 100%;
  background-image: linear-gradient(to right, #F23D6D, #FA00A5);
  width: 100%;
  border-radius: 50px;

}


.bar-fin {
  height: 100%;
  background-image: linear-gradient(to right, #F26241, #FA3C00);
  width: 100%;
  border-radius: 50px;
}


.bar-adm {
  height: 100%;
  border-radius: 50px;
  background-image: linear-gradient(to right, #45DD8B, #32D94B);
  width: 100%;
}



.bar-com {
  height: 100%;
  border-radius: 50px;
  background-image: linear-gradient(to right, #F2BB13, #E67D1C);
  width: 100%;
}


.bar-mkt {
  height: 100%;
  border-radius: 50px;
  background-image: linear-gradient(to right, #7800D6, #AB63F2);
  width: 100%;
}

.bar-adm-pd {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #32D94B;
}


.bar-adm-tec {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #45DD8B;
}

.bar-adm-prs {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #4192F2;
}

.bar-adm-net {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #3A00DF;
}

.bar-adm-est {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #2d00a9;
}

.bar-com-pd {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #F2BB13;
}


.bar-com-mec {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #E67D1C;
}


.bar-com-est {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA3C00;
}

.bar-com-pss {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FF0F0F;
}

.bar-com-inv {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #ce0000;
}


.bar-dir-est {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #F23D6D;
}

.bar-dir-inv {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA00A5;
}

.bar-dir-pss {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA0FFF;
}

.bar-dir-jur {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #7800D6;
}

.bar-dir-net {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #240060;
}

.bar-fin-prexp {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #F26241;
}

.bar-fin-mod {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA3C00;
}

.bar-fin-com {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FF570F;
}

.bar-fin-log {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FF8A00;
}

.bar-fin-ben {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #F2BB13;
}

.bar-mkt-mec {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #7800D6;
}

.bar-mkt-est {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #AB63F2;
}

.bar-mkt-pss {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA0FFF;
}

.bar-mkt-pssc {
  height: 100%;
  border-radius: 50px;
  width: 100%;
  background-color: #FA00A5;
}


.progress p {
  align-items: end;
  text-align: right;
  padding-right: 20px;
  font-size: 12pt;
  font-family: 'manrope';
  font-weight: bold;
  color: white;
}


.progress {
  width: 100%;
  height: 21px;
  background-color: #E0E0E0;
  border-radius: 50px;
}

@media screen and (max-width: 575px) {

  .start {
    display: none;
  }

  .box-center-resultado {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: center;
    justify-content: space-between;
  }

  .title-resultado{
    width: 100%;
  }

  .line-text {
    margin: auto;
    height: 22rem;
    width: 100%;
  }

  .box-resultado-text {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }


  .bem-vindo h1 {
    font-size: 14pt;
  }

  .line-1 {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .line-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 37rem;
    height: 50rem;
    margin-top: 1rem;
  }

  .line-3 {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    width: 100%;
    height: 40rem;

  }

  .line-4, .line-5, .line-6, .line-7 {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    height: 43rem;
  }

  .line-5{
    margin-top: 5rem;
    height: 50rem;
  }


  .box-center-resultado {
    margin: center;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }



  .box-resultado-geral-p1 {
    width: 90%;
    margin: auto;
    height: 26rem;
    margin-left: -0.7rem;
  }

  .box-resultado-geral-p2 {
    width: 90%;
    margin-top: 1rem;
    height: 20rem;
    margin-left: -0.7rem;
    margin-right: auto;
  }

  .box-resultado-geral-p3 {
    width: 90%;
    padding-bottom: 3rem;
    padding: 10px;
    height: 100%;
  }

  .box-resultado-geral-p4 {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    height: 100%;
  }

  .content-resultado-2 {
    width: 100%;
  }

  .text-resultado h1 {
    font-size: 11pt;
  }


  .content-dpto {
    width: 100%;
    margin-left: -0.5rem;
  }

  .content-dpto p {
    width: 100%;
  }

  .chart-js, .chart-js2, .chart-js3{
    width: 100%;
  }

  .bar label{
    font-size: 8pt;
  }

  .frase-final{
    display: none;
  }

  .box-footer{
    display: flex;
  }

}