.roundInfos {
    width: 80.7%;
    height: 10rem;
    margin: 3rem 2rem 2rem 5rem;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 2rem;
}

.top2Rem {
    margin-top: 0rem;
}

.lineTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineTitle .statusRound {
    margin-left: 2rem;
}



.listInfo {
    margin-top: 0.5rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    margin: auto;
    padding: 0px 15px 0px 0px;
}

#link {
    text-decoration: none;
    color: black;
}


.newProduct {
    margin: 1rem 1rem 0rem 5rem;
    height: 9rem;
    width: 85%;
    background: #F1F9FF;
    border: 1px solid #0C8CE9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.newProduct p {
    margin-top: 0.5rem;
    color: #0C8CE9;
}

.newProduct svg {
    background: none;
    background-color: none;
    color: #0C8CE9;
}

.noneSvg {
    background: none;
    background-color: none;
    color: #0C8CE9;
}

svg {
    background: none;
}

.gridProducts {
    width: 85%;
    margin: 1rem 1rem 0rem 5rem;
    display: grid;
    grid-template-columns: repeat(3, 0.5fr);
    gap: 15px;
    /* Ajuste o valor do gap conforme necessário */
}

.gridProducts:first-child {
    margin-left: 0px;
}


.products {
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    width: 25.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    box-shadow: #0c8de914 0px 0px 30px 5px;
    cursor: pointer;
    transition: all 0.5s;
    height: 14rem;
}

.products label {
    cursor: pointer;
}

.products:hover {
    box-shadow: #0c8de91e 0px 0px 30px 5px;
    border: #0C8CE9 1px solid;
    background: #F1F9FF;
}

.products .img {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 14rem;
}

.img img {
    max-width: 13rem;
    min-width: 13rem;
    max-height: 100%;
    min-height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.productData {
    display: flex;
    border-radius: 10px;
    width: 45%;
    padding: 15px 10px 10px 25px;
    flex-direction: column;
}

.line {
    width: 95%;
    height: 1px;
    margin-top: 1rem;
    background: #D9D9D9;
}

.lbProduct {
    letter-spacing: 0px;
    color: #9C9C9C;
    font-family: 'Inter';
    font-size: 10pt;
    font-weight: 400;
    padding: 0px;
    margin-top: 10px;
}

.productData h5 {
    font-family: 'Inter';
    font-size: 10pt;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    color: black;
}

.trash {
    background: white;
    height: 1.75rem;
    width: 1.75rem;
    position: absolute;
    margin-left: 23rem;
    margin-top: .5rem;
    border-radius: 0.05cm;
    display: flex;
}

.trash svg {
    margin: auto;
}

.trash {
    transition: all 0.5s;
    align-items: center;
    justify-content: center;
}

.trash:hover {
    background: rgba(138, 0, 0, 0.822);
}

.trash:hover svg path {
    stroke: red;
    /* Muda a cor da linha do ícone SVG para vermelho ao passar o mouse */
}