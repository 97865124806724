.boxCalendar {
    border: 1px solid rgb(233, 233, 233);
    background-color: white;
    max-height: 45rem;
    width: 25rem;
    padding: 20px;
    margin: 2rem auto auto auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    font-family: 'Inter';
}

.boxCalendar p{
    color: #696969;
}
.agendar {
    width: 15rem;
    margin: 1rem auto;
    cursor: pointer;
}

.agendar p{
    color: white;
}

.handleDay {
    display: flex;
    width: 15rem;
    margin: 1rem auto;
align-items: center;
text-align: center;
justify-content: center;
height: 2rem;

}

.handleDay p{
    padding-left: 40px;
    padding-right: 40px;
    cursor: pointer;
    color: black;
    font-weight: 500;
    align-items: center;
}

.handleDay div {
    height: 100%;
    width: 15px;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.boxTimes {
    max-height: 75%;
    display: flex;
    flex-direction: column;

}

.time {
    height: 2rem;
    width: 14rem;
    margin: 0.5rem auto 0.2rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    background: #F3F3F3;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 11pt;
}

.time:hover,
.time.selected {
    background: #F1F9FF;
    border: 1px solid #0C8CE9;
    color: #0C8CE9;
}

/* No arquivo agend.css */

.hidden {
    display: none;
}

.visible {
    display: flex;
}