.center-comp {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Inter';
}

.Ico {
    width: 3rem;
}

.inputCert:focus {
    background: none;
    border: 1px solid #0C8CE9;
}

.inputCert:hover {
    background: #F1F9FF;
    border: 1px solid #0C8CE9;
}

.inputCert::placeholder {
    color: #C5C5C5;
    font-size: 10pt;
    font-weight: 400;
}


.inputCert {
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 1px #E1E1E1 solid;
    transition: all 0.5s;
    color: black;
    font-weight: 450;
    letter-spacing: 0px;
    font-size: 10pt;
}

.formdataLogin {
    height: 15rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.lbInpt {
    color: black;
    font-weight: 450;
    letter-spacing: 0px;
    font-size: 11pt;
    font-family: 'Inter';
}


.subForm {
    color: #0C8CE9;
    font-size: 10pt;
    text-align: right;
    height: 2rem;
    width: 20rem;
  
    border-bottom: 1.75px #CFCFCF solid;

}

.subForm p{
    font-weight: 500;
}

button .action {
    padding: 0;
    margin: 0;
}

.action {
    padding: 0;
    margin: 0;
    background-color: #0C8CE9;
    color: white;
    border: none;
    border-radius: 7px;
    display: flex;
    width: 20rem;
    margin-top: 2rem;
    transition: all 0.5s;
    height: 2.5rem;

}

.action:hover {
    padding: 0;
    background-color: #0b7fd1;
    color: white;
    border: none;

}


.action p {
    font-family: 'Inter';
    font-size: 12pt;
    font-weight: 500;
    margin: auto;
    padding: 0;
    text-align: center;
    align-items: center;
}

.no-acc{
    margin-top: 1rem;
    height: 1rem;
    width: 20rem;
}

.no-acc p{
color: #C5C5C5;
    font-size: 10pt;
    text-align: left;
    font-weight: regular;
}
.no-acc strong{
    padding-left: 5px;
    color: #0C8CE9;
    text-decoration: underline;
    font-family: 'Inter';
    font-weight: 500;
    
}


.icoText{
    color: black;
    font-size: 20pt;
    font-weight: 400;
}

.top2{
    margin-top: 2rem;
}

@media (max-width: 480px) {
    .formdataLogin {
        margin-top: 1.5rem;
    }

    .formdataLogin input{
        font-size: 10.5pt;
    }


    .action {
        height: 2.2rem;
    }

    .icoText {
        font-size: 18pt;
    }
}