.LoginPage {
    display: flex;
    flex-direction: row;
}

.FormCenter {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 97vh;
}

.translate {
    height: 2.3rem;
    width: 16.1rem;
    align-items: end;
    margin-left: auto;
    margin-right: 2.5rem;
}

.FormLogin {
    margin: 2rem  auto 0 auto;
    height: 25rem;
    display: flex;
}