.homeContent {
    background: #F8F8F8;
    width: 100%;
    min-height: 85vh;
    padding-bottom: 2rem;
}

.menuContent {
    background: #F8F8F8;
    width: 100%;
    min-height: 85vh;
    padding-bottom: 2rem;

}