#category-select {
    width: 20rem;
    margin-bottom: 1rem;
}


#selectButton {
    margin-top: 0;
    display: flex;
    padding: 0.75rem;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;
    border: 1px solid #E1E1E1;
    background: #F3F3F3;
    width: 18.5rem;
    height: 1rem;
}

#selectedValue {
    color: #C5C5C5;
    font-weight: 400;
    font-family: 'Inter';
    font-size: 11pt;
}

#down {
    margin-top: 0.32rem;
}

#up {
    margin-top: -0.1rem;
}

#up,
#down {
    width: 1.2rem;
    height: 1.2rem;
    color: #C5C5C5;
}

#chevrons #up {
    display: none;
}

#options-view-button:focus+#selectButton,
#options-view-button:checked+#selectedButton {
    outline: 1px solid #0C8CE9;
}

#category-select:has(#options-view-button:checked) label,
#options-view-button:checked+#select-button #chevron {
    color: #0C8CE9;
}


#options-view-button:checked+#selectButton #down {
    display: none;
}

#options-view-button:checked+#selectButton #up {
    display: block;
    color: #0C8CE9;
}


#category-select {
    position: relative;
}

#options-view-button {
    all: unset;

    position: absolute;
    inset: 0;

    cursor: pointer;
    z-index: 3;
    height: 2rem;
}


#options {
    margin-top: 0rem;

    border-radius: .375rem;
    border: 1px solid #E1E1E1;
    background: #F3F3F3;

    display: none;
}


.option {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 0.75rem;
    height: 1rem;
    width: 18.5rem;
    border-bottom: #E1E1E1 1px solid;
    transition: all 0ms;
    position: relative;
    font-weight: 500;
    font-size: 11pt;
}

.option:first-child {
    border-top-right-radius: .375rem;
    border-top-left-radius: .375rem;
}

.option:last-child {
    border-bottom-right-radius: .375rem;
    border-bottom-left-radius: .375rem;
}



.option:has(input:checked),
.option:hover {
    background-color: #F1F9FF;
    color: black;
    outline: 1px solid #0C8CE9;
}


.option:has(input:focus) {
    background-color: #F1F9FF;
    color: black;
}

.option input[type="radio"] {
    all: unset;
    inset: 0;
    position: absolute;

    cursor: pointer;
}


#category-select:has(#options-view-button:checked)+#options {
    display: block;
}

#selectBox {
    width: 20rem;
}


.medBox{
    width: 8rem;
}
.minBox{
    width: 4rem;
}