.logo{
  min-height: 5rem;
  max-height: 6rem;
  width: 12rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.logo img{
  height: 4rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 575px) {
  .logo{
    min-height: 5rem;
    max-height: 6rem;
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .logo img{
    height: 4rem;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

}

.all-bg{
  width: 100%;
  height: 100%;
  background-color: #000000;
}