.formdataRegister {
    width: 20rem;
    height: 10rem;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
}
.formdataRegisterInpt {
    width: 20rem;
    max-height: 10rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

}

.formLarge{
    max-height: 24rem;
}

.formLargePlus{
    height: 24rem;
}

.formdataRegister button {
    background: #F3F3F3;
    border: 1px #E1E1E1 solid;
    width: 9rem;
    height: 9rem;
    text-align: center;
    align-items: center;
    display: flex;
    color: #6C6C6C;
}


.formdataRegister button:hover {
    background: #F1F9FF;
    border: 1.5px solid #0C8CE9;
    transition: all 0.4s;
    color: #0C8CE9;
}

.formdataRegister .selected {
    background: #F1F9FF;
    border: 1.5px solid #0C8CE9;
    color: #0C8CE9;
}



.formdataRegister button:focus {
    background: #F1F9FF;
    border: 1.5px solid #0C8CE9;
}


.formdataRegister button p{
margin: auto;
font-family: 'Inter';
font-weight: 500;
}

.termInfo{
    font-size: 9pt;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #C5C5C5;
}


.validInfos{
color: red;
width: 18.5rem;
padding: 10px;
height: 0.500rem;
border: red 1px solid;
align-items: center;
text-align: center;
justify-content: center;
background: rgba(255, 0, 0, 0.082);
display: flex;
margin-bottom: 1rem;
border-radius: 5px;
font-size: 10pt;
transition: all 1s;
}



/* Adicione este código ao seu arquivo de CSS */
.loading-circle {
    border: 4px solid #f3f3f3; /* Cor do fundo */
    border-top: 4px solid #3498db; /* Cor do círculo */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    animation: spin 2s linear infinite;
    margin-top: 3rem; /* Espaçamento entre o botão e o círculo de carregamento */
}

/* Animação de rotação */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.inptLarge{
    height: 8rem;
}

.addressLine {
    width: 20rem;
    height: 4rem; /* Ajuste a altura total conforme necessário */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Duas colunas 
     largura igual */
    grid-template-rows: auto auto; /* Duas linhas automáticas */
    margin-bottom: 1rem;
}



.addressLine label:nth-of-type(1) {
    grid-column: 1; /* Coloca o primeiro label na primeira coluna */
    grid-row: 1; /* Coloca o primeiro label na primeira linha */
}

.addressLine h1:nth-of-type(1) {
    grid-column: 1; /* Coloca o primeiro h1 na primeira coluna */
    grid-row: 2; /* Coloca o primeiro h1 na segunda linha */
}

.addressLine label:nth-of-type(2) {
    grid-column: 2; /* Coloca o segundo label na segunda coluna */
    grid-row: 1; /* Coloca o segundo label na primeira linha */
    align-self: start; /* Alinha o label no início da linha */
}

.addressLine h1:nth-of-type(2) {
    grid-column: 2; /* Coloca o segundo h1 na segunda coluna */
    grid-row: 2; /* Coloca o segundo h1 na segunda linha */
    align-self: end; /* Alinha o h1 no fundo da célula */
}

.gridStart{
    align-self: start
}


.gridEnd{
    align-self: start
}


button.disabled {
    opacity: 0.6; /* Torna o botão visualmente desativado */
    cursor: not-allowed; /* Muda o cursor para mostrar que não é clicável */
  }
  