@font-face {
  font-family: "manrope";
  src: url("./assets/fonts/Manrope/Manrope-SemiBold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "manrope";
  src: url("./assets/fonts/Manrope/Manrope-Bold.ttf");
  font-weight: bold;
}


@font-face {
  font-family: "manrope";
  src: url("./assets/fonts/Manrope/Manrope-Regular.ttf");
  font-weight: 500;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 50px;
  transition: 0.2s;
  text-decoration: none;
  font-family: 'Inter';
}

body {
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  font-family: 'Inter';
  font-weight: normal;
}