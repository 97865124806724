.boxProducts{

    margin-left: 5rem;

}

#price{
    font-size: 17pt;
}


#showMore{
    margin-top: 2rem;
    color: #0C8CE9;
    font-weight: 500;
}