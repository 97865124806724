.SucessCenter{
    height: 100vh;
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Inter';
}

.SucessCenter h3{
    margin-bottom: 0.5rem;
    font-size: 18pt;
}

.ctaSmall{
    width: 12rem;
}

.IcoSmall{
    height: 37px;
    padding-bottom: 5px;
}