.box-center-change{
  width: 60%;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.line-title{
  height: 3rem;
  width: 25rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 3rem;
  align-items: center;
  text-align: center;

}

.line-title h1{
  color: white;
  font-family: 'manrope';
  font-weight: bold;

}

.line-select{

  height: 10rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: -10rem;
  display: flex;
  flex-direction: row;
}

.card-1, .card-2{
  height: 10rem;
  background-color: #171717;
  border: 1.5px solid #3C3C3C;
  transition: 0.75s;
  cursor: pointer;
  color: white;
  align-items: center;
  text-align: center;
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.change{
  margin-top: 2rem;
  width: 2.5rem;
}

.card-1 h1,  .card-2 h1{
  font-size: 15pt;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}


.card-1 svg,  .card-2 svg{
  fill: white;
}

.card-1:hover, .card-2:hover {
  background-color: white;
  color: black;
  box-shadow: #fefefe42 3px 3px 50px;
}

.card-1:hover svg, .card-2:hover svg {
  fill: black;
}

@media screen and (max-width: 575px) {
  .line-title h1{
font-size: 19pt;
  }

  .line-title{
    margin-left: auto;
    margin-right: auto;
    width: 19rem;
    height: 30px;
    margin-bottom: 0;
    margin-top: -8%;
  }

  .line-title h1{
    font-size: 15pt;
  }
  .card-1, .card-2{
    height: 10rem;
    width: 10rem;
  }

  .line-select{
    width: 100%;
    height: 55%;
    margin-top: 2em;
    flex-direction: column;
  }

  .box-center-change{
    width: 80%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .change{
    margin-top: 2rem;
    width: 3.5rem;
    height: 3.5rem;
  }
  .card-1 h1,  .card-2 h1{
    margin-top: 1rem;
    font-size: 16pt;
  }
 

}