#loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #loader-container img {
width: 400PX;
margin: auto;
left: 40%;
  }

  @media screen and (max-width: 575px){
    #loader-container img {
        width: 400PX;
     margin-left: 10%;
     margin-right: auto;
     margin-top: auto;
     margin-bottom: auto;
        left: 50%;}

  }