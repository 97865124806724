.LoginPage {
    display: flex;
    flex-direction: row;
}

.FormCenter {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.translate {

    height: 2.3rem;
    width: 8.1rem;
    align-items: end;
    margin-left: auto;
    margin-right: 2.5rem;
}

.FormLogin {
    margin: 5rem auto 0 auto;
    height: 40rem;
    display: flex;
}


@media (max-width: 768px) {
    .LoginPage {
        flex-direction: column;
    }

    .FormCenter {
        width: 90%;
        margin: 2rem auto;
    }

    .translate {
        margin-right: 1rem;
    }

    .FormLogin {
        margin-top: 3rem;
        height: auto;
    }

    .banner {
        display: none;
    }
}

@media (max-width: 480px) {
    .translate {
        display: flex;
        width: 6.5rem;
        height: 2rem;
        margin-top: 1rem;
        left: 57%;

        position: absolute;
    }



    .FormLogin {
        margin-top: 2rem;
        padding: 1rem;
    }

    .banner {
        display: none;
    }
}