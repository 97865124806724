.logout {
    background: none;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0;
    align-items: center;
    text-align: center;
    justify-content: end;

    height: 2rem;
}

.logout:hover {
    background: none;
    box-shadow: none;
    border: none;
}