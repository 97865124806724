.menuRounds {
    background: white;
    width: 100%;
    height: 4rem;
    border-bottom: 1px #c2c2c24c solid;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.logoMenu {
    height: 3rem;
    
}

.hamburger {
    display: none;
    background: none;
    border: none;
    width: 2rem;
    background-color: none;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 0rem;
    cursor: pointer;
}

.hamburgerIcon {
    font-size: 1.5rem;
    color: #0C8CE9;
}

.menuItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 3rem;
}

.leftMenu {
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-left: 5rem;
}

.leftMenu p {
    font-size: 11pt;
    font-weight: 400;
    font-family: 'Inter';
    padding: 10px;
    margin-left: 3rem;
}

.leftMenuItems,
.rightMenuItems {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rightMenuItems{
width: 36rem;
}

.leftMenuItems p {
    font-size: 11pt;
    font-weight: 400;
    font-family: 'Inter';
    padding: 10px;
    margin-left: 3rem;
}

.menuLeftSpace {
    margin-left: 2rem;
}

.ingressRound {
    height: 1.5rem;
    width: 12rem;
    background: #0C8CE9;
    color: white;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 10pt;
    text-align: center;
    padding: 5px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
    cursor: pointer;
}

.ingressRound:hover {
    background: #0b78c7;
}

.marginR {
    margin-right: 1rem;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .menuItems {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 4rem;
        left: 0;
        width: 100%;
        background: white;
        border-top: 1px solid #c2c2c2;
    }

    .menuItems.show {
        display: flex;
    }

    .leftMenuItems p,
    .rightMenuItems {
        margin: 1rem 0;
        text-align: center;
        width: 100%;
    }

    .ingressRound {
        margin: 1rem auto;
        width: 80%;
    }

    .marginR {
        margin: 1rem 0;
    }
}

@media (max-width: 480px) {
    .hamburgerIcon {
        font-size: 1.25rem;
    }

    .leftMenuItems,
    .rightMenuItems {
        height: 12rem;
        display: flex;
        flex-direction: column;
        border: black 1px solid;
    }

    .leftMenuItems p,
    .rightMenuItems {
        font-size: 10pt;
    }

    .hamburger {
        margin-top: 0.5rem;
        margin-right: 2rem;
        margin-left: 0rem;
    }

    .hamburger span {
        height: 2rem;
        width: 2rem;
    }

    .leftMenu {
        margin-left: 2rem;

    }

    .logoMenu {
        margin: 0px;
    }

    .show {
        height: 75vh;
        border: 1px solid red;
        text-align: center;
        align-items: center;
    }

    .menuItems {
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}