@font-face {
    font-family: "sweet";
    src: url("../../assets/fonts/Sweet/SweetSansPro-Medium.otf");
    font-weight: normal;
}

.entrar {
    margin-top: -1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    min-height: 33rem;
    width: 70%;
    color: #f1f1f1;
    text-align: center;
}

.center-entrar {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 35rem;
    width: 50%;
}

select {
    background-color: #171717;
    backdrop-filter: blur(20px);
    border: 1.5px solid #3C3C3C;
    transition: 0.75s;
    height: 3rem;
    color: #f1f1f1;
    width: 100%;
    border-radius: 12px;
}

.inpdatalist {
    background-color: #171717;
    border: 1.5px solid #3C3C3C;
    transition: 0.75s;
    height: 3rem;
    color: #f1f1f1;
    width: 100%;
    border-radius: 12px;
}

.inpdatalist {
    color: #f1f1f179;
    padding-left: 20px;
    font-family: 'manrope';
    font-weight: 500;
    letter-spacing: 1.2px;
}

.accTerm {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.accTerm input {
    margin-right: 0.55rem;
    height: 1rem;
    width: 1rem;
}


.accTerm p {
    font-size: 10pt;
    font-family: 'manrope';
    font-weight: normal;
    letter-spacing: 0px;
}




.insert-info {
    background-color: #171717;
    border: 1.5px solid #3C3C3C;
    transition: 0.75s;
    height: 3rem;
    color: #f1f1f1;
    width: 95%;
    border-radius: 12px;
}

input {
    font-family: 'manrope';
    font-weight: 500;
    letter-spacing: 1.2px;
    padding-left: 20px;
    border: none;
}

/* styles.css */

.red-border {
    border-bottom: 1.65px solid #FF0F0F;
    border-bottom-width: 70%;
  }

input:focus {
    outline: none;
    background-color: #171717;
  }

  select:focus{
    outline: none;
    border: none;}
    select::placeholder{
        background-color: #171717;
    }
    select:hover{
        background-color: #171717;
    }

.insert-info:hover {
    border: 1px solid #ffffff;
    box-shadow: #fefefe22 0px 3px 55px;
}

.inputs {
    height: 35rem;
    width: 92%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;

}

.input-info {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
}

label {
    font-size: 9pt;
    color: rgba(255, 255, 255, 0.571);
    letter-spacing: 4px;
    font-weight: normal;
    font-family: 'sweet';
    padding-bottom: 5px;
    text-align: left;
}

.button-box {
    margin-top: auto;
    display: flex;
    align-items: center;
    height: 10rem;
}

.start-diagnostic {
    height: 3rem;
    border-radius: 12px;
    background-color: #ffffff;
    width: 26.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    cursor: pointer;
    transition: 0.4s;
    color: #000000;
    align-items: center;
    text-align: center;
    font-family: 'manrope';
    font-weight: bold;
    font-size: 12pt;
    letter-spacing: 0px;
    padding-left: 0PX;
}

.start-diagnostic:hover {
    background-color: #d3d3d3;
}

.logo {
    height: 40%;
}

/* Novo estilo para overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
  }
  
  .close-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }
  
  .close-overlay:hover {
    color: #000;
  }


@media screen and (max-width: 575px) {

    .center-entrar {
        width: 99%;
        height: 45%;
    }

    .logo {
        height: 30%;
    }

    body {
        height: 40%;
    }

    .start-diagnostic {
        width: 19rem;
        justify-content: center;
        text-align: center;
        margin-top: 0.5rem;
    }

    .entrar {
        width: 95%;
        height: 6rem;
        margin-top: -10%;
        height: 35rem;

    }

    span {
        font-size: 1.5rem;
    }

    .inputs {
        margin-top: 0.3rem;
        height: 31rem;
    }

    .entrar h2 {
        width: 20rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        height: 20px;
        font-size: 14pt;
    }

    input,
    select {
        font-size: 16px;
    }



}