.banner {
    width: 100vh;
    height: 100vh;
    background-image: url(./banner.png);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
}

.banner-title {
    height: 5rem;
    width: 25rem;
    margin-top: 5rem;
    margin-left: 3rem;
}


.banner-title h3 {
    color: white;
    font-size: 20pt;
    font-weight: 300;
    font-family: 'Inter';
}

.banner-title strong {
    color: white;
    font-size: 20pt;
    font-family: 'Inter';
}